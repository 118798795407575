import React from 'react'

const  Hero = ()=> {
  return (
    <div className="hero container">
      <div className="grid">
        <h2 className="hero--title">Hello, my name is Mofid jobakji </h2>
        <p className="hero--subtitle">
          I’m a Front-End Developer with 6+ years commercial experience creating
          successful websites. located in istanbul.
        </p>
      </div>
    </div>
  )
}

export default Hero;