
import React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Seo from "../components/seo"

//import Gallery from '../components/gallery'

const IndexPage = (/* { projects: { nodes: projects } } */) => {
  return (
    <Layout>
      <Seo title="Moufeed Jobakji" />
      <div className="homePage">
        <Hero />
       {/*  <Gallery projects={projects} /> */}
      </div>
    </Layout>
  )
}


export default IndexPage

/*
export const query = graphql`
  query homePage {
    projects: allMarkdownRemark(
      sort: { order: [ASC], fields: [frontmatter___title] }
      limit: 6
      filter: { frontmatter: { featured: { eq: false } } }
    ) {
      nodes {
        frontmatter {
          slug
          title
          featureImage {
            childImageSharp {
              fluid {
                src
                srcWebp
                srcSet
              }
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          css
        }
        id
      }
    }
  }
`
    */
